<template>
  <div class="stake-box__membership--skeleton">
    <q-skeleton width="100%" height="74px" />
  </div>
</template>

<style lang="scss" scoped>
.stake-box__membership--skeleton {
  padding: 10px;
  width: 100%;
  margin: 0 auto;
  height: 96px;

  .q-skeleton {
    border-radius: $border-radius;
  }
}
</style>
