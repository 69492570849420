<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
import { evaClose } from '@quasar/extras/eva-icons'
import hcLogo from '~/assets/img/logo/holders-club-logo-dark.svg'
import logoDark from '~/assets/img/logo/jpool-logo-dark.svg'

defineProps({
  isMembership: {
    type: Boolean,
    default: false,
  },
})

const membershipStore = useMembershipStore()

const text = computed(() => membershipStore.isHoldersClubMember
  ? 'Congrats on becoming a member of the <br> <a href="/holders-club" target="_blank">JPool Holders Club</a>'
  : 'Become a member of <a href="/holders-club" target="_blank">JPool Holders Club</a> <br> to enjoy exclusive rewards and privileges')
</script>

<template>
  <q-dialog @hide="membershipStore.isRegister = false">
    <q-card class="stake-success">
      <q-card-section class="stake-success__body">
        <q-btn
          v-close-popup
          padding="sm"
          color="transparent"
          unelevated
          class="absolute-top-right"
          :icon="evaClose"
          size="sm"
        />

        <div class="stake-success__logo">
          <img alt="JPool" :src="logoDark">
          <span>
            liquid staking
          </span>
        </div>

        <div class="stake-success__info">
          <div class="stake-success__info__title">
            {{ $t('stake.successDialog.success') }}
          </div>
          <div class="stake-success__info__text">
            {{ $t('stake.successDialog.text') }}
          </div>
        </div>

        <div class="stake-success__footer">
          <div v-if="!membershipStore.isHoldersClubMember || membershipStore.isRegister" class="stake-success__footer__text" v-html="text" />
          <div class="stake-success__footer__links">
            <a
              href="/holders-club"
              target="_blank"
              class="stake-success__footer__icon"
            >
              <span v-if="!membershipStore.isHoldersClubMember">join</span>
              <img :src="hcLogo" alt="holders club logo">
            </a>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<style lang="scss">
  .stake-success {
  text-align: center;
  color: $gray;
  background: $primary !important;
  width: 450px;
  max-width: 98vw;

  &__body {
    padding: 20px 0 0 !important;
    position: relative;
  }

  &__header {
    margin-bottom: -20px;
    margin-top: 5px;
  }

  &__logo {
    position: relative;
    padding-bottom: 12px;
    width: 196px;
    margin: 0 auto;
    img {
      height: 54px;
    }
    span {
      color: $gray-5;
      text-transform: uppercase;
      font-family: $font-monrope;
      white-space: nowrap;
      font-weight: 800;
      font-size: 11px;
      letter-spacing: 1px;
      position: absolute;
      bottom: 16px;
    }
  }

  &__info {
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__title {
      color: $positive;
      width: 100%;
      border-bottom: 1px solid $gray-4;
      font-family: $font-fredoka;
      font-size: 50px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
    }

    &__text {
      color: #000;
      text-align: center;
      font-family: $font-monrope;
      font-size: 17px;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      padding: 15px 0;
    }
  }

  &__footer {
    background: white;

    &__text {
      color: #fff;
      background-color: $gray;
      text-align: center;
      font-family: $font-monrope;
      font-size: 16px;
      font-weight: 400;
      padding: 14px 40px;
      line-height: normal;

      @media (max-width: $breakpoint-xs) {
        padding: 14px 10px;
      }

      a {
        font-weight: 800;
        text-decoration: none;
        color: #fff;
      }
    }

    &__links {
      background-color: $primary;
      display: flex;
      min-height: 124px;
      align-items: center;
      justify-content: center;
    }

    &__icon {
      position: relative;
      font-size: 20px !important;
      width: 198px;
      height: 52px;
      display: flex;
      flex-direction: column;
      text-decoration: none;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }

      span {
        color: #fff;
        font-family: $font-metana;
        font-size: 13px;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin-left: -30px;
        margin-top: -10px;
      }
    }
  }
}
</style>
