<script lang="ts" setup>
import { matCheck, matClose } from '@quasar/extras/material-icons'
import { Dark } from 'quasar'
import { useWallet } from 'solana-wallets-vue'
import holdersClubLogo from '~/assets/img/logo/holders-club-logo.svg'
import holdersClubLogoDark from '~/assets/img/logo/holders-club-logo-dark.svg'
import jpoolLogo from '~/assets/img/logo/jpool-logo.svg'
import jpoolLogoDark from '~/assets/img/logo/jpool-logo-dark.svg'

const depositStore = useDepositStore()
const membershipStore = useMembershipStore()

const { connecting } = useWallet()

const isSkeleton = ref(true)

const membershipDialog = ref(false)

const earlyBirdBooster = computed(() => {
  const rate = membershipStore.config?.rewardProviders?.find(p => p.id === 'base')?.config?.boostCurrentRate ?? 1
  const normalizedRate = Math.round(rate * 100) / 100
  return normalizedRate <= 0 ? 1 : normalizedRate
})

const jpoolLogoUrl = computed(() => {
  return Dark.isActive ? jpoolLogoDark : jpoolLogo
})

const holdersClubLogoUrl = computed(() => {
  return Dark.isActive ? holdersClubLogoDark : holdersClubLogo
})

watch(() => connecting.value, (c) => {
  if (depositStore.firstLogin) {
    if (c) {
      isSkeleton.value = true
      return
    }
    setTimeout(() => {
      isSkeleton.value = false
      depositStore.firstLogin = false
    }, 1000)
  } else {
    isSkeleton.value = false
  }
}, { immediate: true })
</script>

<template>
  <stake-box-membership-skeleton v-if="isSkeleton" />
  <q-card-section
    v-else
    class="stake-box__section"
  >
    <q-expansion-item
      v-model="depositStore.toBeMembership"
      class="stake-box__membership"
      hide-expand-icon
    >
      <template #header>
        <div class="stake-box__membership--logo" :class="{ 'logo-full': depositStore.toBeMembership }" @click.stop>
          <router-link to="/holders-club">
            <img :src="jpoolLogoUrl" class="jpool-logo">
            <img :src="holdersClubLogoUrl" class="hc-logo">
          </router-link>

          <span v-if="depositStore.toBeMembership" @click="membershipDialog = true">{{ $t('stakeBox.membership.learnMore')
          }}</span>
        </div>
        <div class="stake-box__membership--info" @click.stop>
          <q-skeleton v-if="membershipStore.state.loading" width="94px" height="18px" style="border-radius: 6px;" />
          <div v-else-if="!membershipStore.isHoldersClubMember">
            {{ $t('stakeBox.membership.startEarning') }}
          </div>
          <router-link v-else to="/holders-club">
            {{ $t('stakeBox.membership.welcomeBack') }}
          </router-link>
        </div>
        <q-toggle
          v-if="!membershipStore.isHoldersClubMember" v-model="depositStore.toBeMembership" class="styled-toggle"
          toggle-order="tf" color="dark-green" keep-color label=""
          :unchecked-icon="matClose" :checked-icon="matCheck"
        />
      </template>

      <div class="stake-box__membership--details">
        <div class="detail-title">
          {{ $t('stakeBox.membership.details.title') }}
        </div>

        <div class="detail-item">
          <div class="detail-item__info">
            <i-app-j-points width="18px" height="18px" />
            10
          </div>
          <div class="detail-item__text">
            {{ $t('stakeBox.membership.details.jpoints') }}

            <span data-name="detail-tooltip">
              i
              <q-tooltip persistent class="stake-detail-jpoints-tooltip">
                <div class="title">
                  {{ $t('stakeBox.membership.details.jpointsTooltip.title') }}
                </div>
                <div class="desc">
                  {{ $t('stakeBox.membership.details.jpointsTooltip.desc') }}
                </div>
              </q-tooltip>
            </span>
          </div>
        </div>

        <div class="detail-item">
          <div class="detail-item__info">
            <i-app-energy color="#ffcb25" width="18px" height="18px" />
            <span>x</span>{{ earlyBirdBooster }}
          </div>
          <div class="detail-item__text">
            {{ $t('stakeBox.membership.details.earlyBird') }}
            <span data-name="detail-tooltip">
              i
              <q-tooltip persistent class="stake-detail-early-bird-tooltip">
                <div class="left">
                  <i-app-energy color="#ffcb25" width="32px" height="39px" />
                </div>
                <div class="right">
                  <div class="right-top">
                    <div class="right-top__left">
                      ×{{ earlyBirdBooster }}

                      <span>{{ $t('stakeBox.membership.details.earlyBirdTooltip.currentMultiplier') }}</span>
                    </div>
                    <div class="right-top__right">
                      {{ $t('stakeBox.membership.details.earlyBirdTooltip.booster') }}
                    </div>
                  </div>

                  <div class="right-body">
                    <div class="title">
                      {{ $t('stakeBox.membership.details.earlyBirdTooltip.title') }}
                    </div>
                    <div class="desc">
                      {{ $t('stakeBox.membership.details.earlyBirdTooltip.desc') }}
                    </div>
                  </div>
                </div>
              </q-tooltip>
            </span>
          </div>
        </div>

        <div class="detail-item">
          <div class="detail-item__info">
            <i-app-gift color="#ffcb25" width="16px" height="16px" />
            <span style="transform: rotate(45deg);">x</span> 1
          </div>
          <div class="detail-item__text">
            {{ $t('stakeBox.membership.details.nft') }}
          </div>
        </div>
      </div>
    </q-expansion-item>
    <div
      v-if="!membershipStore.isHoldersClubMember && !membershipStore.state.loading"
      class="stake-box__membership--badge" :class="{ 'badge-show': depositStore.toBeMembership }"
    >
      <i-app-badge-jp />
      <span>
        {{ $t('stakeBox.membership.badge') }}
      </span>
    </div>
  </q-card-section>

  <stake-membership-dialog v-model="membershipDialog" />
</template>
